.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  position: relative;
  font-family: "sf compact display";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.CustomWheel {
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  position: relative;
  width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10.5px 24px;
  margin-top: 40px;
  gap: 10px;
  color: white;

  width: 128px;
  height: 38px;

  box-shadow: 0px 0px 12px rgba(183, 150, 234, 0.25);
  border-radius: 6px;

  font-family: "sf compact display";
  font-style: normal;
  font-weight: 556;
  font-size: 14px;
}

button:disabled {
  background: #707070 !important;
  color: #acacac;
}

button:focus {
  background-image: linear-gradient(rgb(0 0 0/20%) 0 0);
}

.logoContainer {
  max-width: 400px;
  max-height: 440px;
  position: absolute;
  height: 81vw;
  display: flex;
  align-items: center;
}

.logo {
  z-index: 100;
  width: 17vw;
  max-width: 84px;
}

select {
  width: 100px;
  width: 128px;
  height: 38px;
  margin-top: 8px;

  box-shadow: 0px 0px 12px rgba(183, 150, 234, 0.25);
  border-radius: 6px;
}

.styleSelect {
  margin-bottom: 30px;
}
